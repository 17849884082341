window.addEventListener('load', () => {
  let browserHeight = window.innerHeight

  if (window.matchMedia('(min-width: 770px)').matches) {
    if (browserHeight > 650) {
      document.getElementsByClassName('mainslider-wrap')[0].style.height = Math.round(browserHeight - 48) + 'px'
    }
  } //matchMedia for PC


  // reservation
  // find checkbox
  if (document.getElementById('checkbox') != null && document.getElementsByClassName('js-comfirm')[0] != null) {
    document.getElementsByClassName('l-box-reserv')[0].onclick = () => {
      document.getElementsByClassName('l-box-reserv')[0].classList.add('active')
    } //onclick

    document.getElementById('checkbox').onclick = () => {
      if (document.getElementsByClassName('js_watch_input')[0].value == "") {
        document.getElementsByClassName('js_watch_input')[0].classList.add('current-error')
        document.getElementsByClassName('js_error')[0].innerHTML = "未入力の項目があります"
      }
      if (document.getElementsByClassName('js_watch_input')[1].value == "") {
        document.getElementsByClassName('js_watch_input')[1].classList.add('current-error')
        document.getElementsByClassName('js_error')[0].innerHTML = "未入力の項目があります"
      }
      if (document.getElementsByClassName('js_watch_input')[0].value !== "" && document.getElementsByClassName('js_watch_input')[1].value !== "") {
        document.getElementsByClassName('js_error')[0].innerHTML = ""
        document.getElementsByClassName('checkbox')[0].classList.add('checked')
        document.getElementsByClassName('js_submit')[0].removeAttribute('disabled')
      }
      // } else {
      //   document.getElementsByClassName('js_submit')[0].removeAttribute('disabled')
      // }

    } //onclick
  }
  // end find checkbox

  let inputElements = document.getElementsByClassName('input-cell')
  for (let $i = 0; $i < inputElements.length; $i++) {
    inputElements[$i].onclick = () => {
      inputElements[$i].classList.add('active')
    } //onclick
  } //end for

  // MAIN VISUAL HIDE LOGO.
  // fail safe.
  if (document.getElementsByClassName('mainslider-wrap')[0] != null) {
    let hideHeaderObj = document.getElementsByClassName('mainslider-wrap')[0]
    hideHeaderObj.addEventListener('mouseover', function(event){
      document.getElementsByClassName('center-logo')[0].classList.add('hide')
    })
    hideHeaderObj.addEventListener('mouseleave',function(event){
      document.getElementsByClassName('center-logo')[0].classList.remove('hide')
    })
  }

  setTimeout(() => {
    document.getElementsByClassName('center-logo')[0].classList.add('hide')
  }, 3250);
  // END MAIN VISUAL HIDE LOGO.
}) // onload
